<template>
  <div class="features pad-60">
    <!--Left Image-->
    <div class="image">
      <img src="@/assets/images/icons/image2.png" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <!--Main Title-->
          <div class="main_title center">
            <h2>مميزاتنا</h2>
          </div>
        </div>
        <div class="col-md-4" v-for="feat in features" :key="feat.id">
          <Features :item="feat"></Features>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Features from "@/components/UI/FeaturedCard.vue";
export default {
  data() {
    return {
      features: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          icon: require("@/assets/images/icons/feat1.svg"),
          title: "الجودة الأفضل",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، ",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          icon: require("@/assets/images/icons/feat2.png"),
          title: "فريق عمل متميز متمكن",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، ",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          icon: require("@/assets/images/icons/feat3.png"),
          title: "الحرص على دقة التفاصيل",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، ",
        },
      ],
    };
  },
  components: {
    Features,
  },
};
</script>

<style></style>
