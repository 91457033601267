var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"partners pad-60"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('carousel',{attrs:{"items":9,"autoplay":true,"loop":true,"nav":false,"dots":false,"dir":"ltr","margin":50,"responsive":{
            0: { items: 1 },
            700: { items: 2 },
            1000: { items: 4 },
            20000: { items: 6 },
            500000: { items: 9 },
          }}},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/sscm.jpg")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/daleel.svg")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/tawuniya.svg")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/langlark.png")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/luster.png")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/moronah.png")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/zal.png")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/lime.png")}})]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/partners/urc.png")}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }