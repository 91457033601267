<template>
  <div class="partners pad-60">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <carousel
            :items="9"
            :autoplay="true"
            :loop="true"
            :nav="false"
            :dots="false"
            dir="ltr"
            :margin="50"
            :responsive="{
              0: { items: 1 },
              700: { items: 2 },
              1000: { items: 4 },
              20000: { items: 6 },
              500000: { items: 9 },
            }"
          >
            <div class="item">
              <img src="@/assets/images/partners/sscm.jpg" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/daleel.svg" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/tawuniya.svg" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/langlark.png" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/luster.png" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/moronah.png" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/zal.png" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/lime.png" />
            </div>
            <div class="item">
              <img src="@/assets/images/partners/urc.png" />
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  components: { carousel },
};
</script>

<style></style>
