<template>
  <div>
    <Header></Header>
    <transition name="fadeInUp" mode="out-in">
      <router-view></router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Layouts/TheHeader.vue";
import Footer from "@/components/Layouts/TheFooter.vue";
export default {
  components: {
    Header,
    Footer,
  },
  name: "Home",
};
</script>
