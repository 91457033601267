<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <!--Foot About-->
        <div class="col-md-3">
          <div class="foot_about">
            <img src="@/assets/images/icons/logo2.png" />
            <span>كل ما تحتاجه في مكان واحد</span>
            <p>تحرص على دعم احتياجات عملاءها و افكارهم بتقديمها افضل الخيارات التقنية و الخبرات العملية لتمكنهم  من الوصول الى التجربة الامثل و تحقيق رؤيتهم التقنية.</p>
          </div>
        </div>
        <!--Links-->
        <div class="col-md-1">

        </div>
        <!--Services-->
        <div class="col-md-3">
          <div class="foot">
            <h4>خدماتنا</h4>
            <ul class="links">
              <li>
                <router-link to="">تصميم اللوجهات و الهوية</router-link>
              </li>
              <li>
                <router-link to="">تصميم و تطوير تطبيقات الجوال</router-link>
              </li>
              <li>
                <router-link to="">برمجة أنظمة الكترونية</router-link>
              </li>
              <li>
                <router-link to="">تطوير المواقع الالكترونية</router-link>
              </li>
              <li>
                <router-link to="">إستضافة | الدعم الفني</router-link>
              </li>
            </ul>
          </div>
        </div>
        <!--Contact-->
        <div class="col-md-3">
          <div class="foot">
            <h4>تواصل معنا</h4>
            <ul class="links">
              <li>
                <span><i class="fal fa-phone"></i></span>
                <a href="https://wa.me/+9660500711915"
                  >0500711915</a
                >
              </li>
              <li>
                <span><i class="fal fa-map-marker-alt"></i></span>
                <a> الرياض</a>
              </li>
              <!-- <li>
                <span><i class="fal fa-whatsapp"></i></span>
                <a href="https://wa.me/+966500711915"
                  >0500711915</a
                >
              </li> -->
              <li>
                <span><i class="fal fa-envelope"></i></span>
                <a href="mailto:info@byeptec.com"
                  >info@byeptec.com</a
                >
              </li>
            </ul>
          </div>
        </div>
        <!--CopyRight-->
        <div class="col-md-12">
          <div class="copyright">
            <p>جميع الحقوق محفوظة  &copy; لعام 2017</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
