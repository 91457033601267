// ======== core Vue
import Vue from "vue";

// ======== Main app
import App from "./App.vue";

// ======== Router
import router from "./router";

// ======== Store
import store from "./store";

// ======== language
store.dispatch("lang_module/handleLang"); // ==> Store (Vuex) <==
store.dispatch("theme_module/handleTheme"); // ==> Store (Vuex) <==

// ========  Start Axios
import axios from "axios";

Vue.prototype.$axios = axios; // Glopal variable

// Global headers
axios.defaults.baseURL = "https://eptec.develobug.com/api/"; // baseurl
axios.defaults.headers.common["cache-control"] = "no-cache";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Accept-language"] =
  store.getters["lang_module/lang"]; // ==> Store (Vuex) <==

// Set Token If Exists
if (store.getters["auth_module/token"]) {
  axios.defaults.headers.common["Authorization"] =
    "bearer" + localStorage.getItem("eptec_App_Token");
}
// ========  End Axios

// ======== Bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
Vue.use(BootstrapVue);



// ======== AOS
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();



// ======= vuetify
import vuetify from "./plugins/vuetify";

// =============> Vue Slike Carousal
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// ========  Main Style
import "./assets/scss/main.scss";



// Register Globbal Components
Vue.component("vue-slick", VueSlickCarousel);

// ======== Attatch and Mount
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
