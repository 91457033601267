<template>
  <div class="feature_card">
    <div class="feat">
      <!--Icon-->
      <div class="icon">
        <img :src="item.icon" />
      </div>
      <!--Title-->
      <div class="title">
        <h4>{{ item.title }}</h4>
      </div>
      <!--Desc-->
      <div class="desc">
        <!-- <p>{{ item.desc }}</p> -->
      </div>
    </div>
    <!--Waves-->
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style></style>
