<template>
  <div class="box14">
    <img class="pic-1" :src="item.image" />
    <div class="box-content">
      <h3 class="title">{{ item.title }}</h3>
      <span class="post">{{ item.type }}</span>
      <!-- <ul class="icon">
        <li>
          <router-link to="" class="main_button work"
            ><span>{{ $t("works.observe") }}</span></router-link
          >
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style>
.box14 {
  position: relative;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  text-align: center;
  overflow: hidden;
  margin-bottom: 20px;
}
.box14 .icon li,
.box14 .post {
  display: inline-block;
}
.box14:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(81, 230, 174, 0.8),
    rgba(101, 136, 239, 0.8)
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.35s ease 0s;
}
.box14:hover:before {
  opacity: 1;
}
.box14 img {
  width: 100%;
  height: auto;
}
.box14 .box-content {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
}
.box14 .box-content:after,
.box14 .box-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.7s ease 0s;
}
.box14 .box-content:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  transform: scale(0, 1);
  transform-origin: 0 0 0;
}
.box14 .box-content:after {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  transform: scale(1, 0);
  transform-origin: 100% 0 0;
}
.box14:hover .box-content:after,
.box14:hover .box-content:before {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.15s;
}
.box14 .title {
  font-size: 21px;
  font-weight: 700;
  color: #fff;
  margin: 15px 0;
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  transition: transform 0.5s ease 0s;
}
.box14:hover .title {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.box14 .post {
  font-size: 14px;
  color: #fff;
  padding: 10px;
  background: #30315d;
  opacity: 0;
  border-radius: 0 19px;
  transform: translate3d(0, -50px, 0);
  transition: all 0.7s ease 0s;
}
.box14 .icon,
.box15 .icon {
  padding: 0;
  list-style: none;
}
.box14:hover .post {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.15s;
}
.box14 .icon {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: -10px;
  left: 0;
  opacity: 0;
  z-index: 1;
  transition: all 0.7s ease 0s;
}
.box14:hover .icon {
  bottom: 20px;
  opacity: 1;
  transition-delay: 0.15s;
}
.box14 .icon li .work {
  background: #fff;
  color: #30315d !important;
  transition: all 0.4s ease 0s;
}
.box14 .icon li a:hover {
  background: #30315d;
  border-color: #30315d;
  color: #fff !important;
}
@media only screen and (max-width: 990px) {
  .box14 {
    margin-bottom: 30px;
  }
}
</style>
