<template>
  <div class="contact_us pad-60" id="contact">
    <!--shape-->
    <div class="shape">
      <img src="@/assets/images/icons/image2.png" />
    </div>
    <div class="shape2">
      <img src="@/assets/images/icons/traingle1.png" />
    </div>
    <div class="container">
      <div class="row">
        <!--Image-->
        <div class="col-md-6">
          <div class="main_title diff_style">
            <h3>تواصل معنا</h3>
          </div>
          <div class="image">
            <img src="@/assets/images/main_section/contact_us.png" />
          </div>
        </div>
        <!--Form-->
        <div class="col-md-6">
          <div class="my_form">
            <form>
              <!-- Start Name -->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="text"
                  v-model="data.name"
                  :placeholder="'الاسم بالكامل'"
                  required
                />
              </div>
              <!-- End Name -->
              <!-- Start phone -->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="number"
                  v-model="data.phone"
                  :placeholder="'رقم الجوال'"
                  required

                />
              </div>
              <!-- End phone -->
              <!-- Start Email -->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="email"
                  v-model="data.email"
                  :placeholder="'البريد الالكتروني'"
                  
                />
              </div>
              <!-- End Email -->
              <!-- Start Message -->
              <div class="input_wrapper">
                <textarea
                  class="form-control"
                  type="text"
                  v-model="data.message"
                  :placeholder="'الرسالة'"
                  required
                ></textarea>
              </div>
              <button type="button" class="main_button" @click="contact">
                <span>إرسال</span>
              </button>
              <!-- End Message -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      data: {
        name: "",
        phone: null,
        email: null,
        message: "",
      },
    };
  },
  methods:{
     contact(){
        // console.log(this.$refs.recaptcha.execute());
        let data = new FormData();
        if(this.data.name=='' || this.data.name==undefined){
          this.$iziToast.error({
              message:"الإسم مطلوب" ,
          });
        }
        else if(this.data.phone=='' || this.data.phone==undefined){
          this.$iziToast.error({
              message:"الجوال مطلوب" ,
          });
        }
        else if(this.data.message=='' || this.data.message==undefined){
          this.$iziToast.error({
              message:"الرسالة مطلوبة" ,
          });
        }else{
          data.append('name',this.data.name);
        data.append('phone',this.data.phone);
        data.append('email',this.data.email);
        data.append('message',this.data.message);

      this.$axios({
        method: "POST",
        url: `contact`,
        data: data,
      })
        .then(() => {
          this.$iziToast.success({
              message:"تم إرسال رسالتك بنجاح" ,
              position:"bottomRight"
          });
      
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          this.$iziToast.error({
              message:err.response.data.message,
              position:"bottomRight"
          });
        });


        this.data.name = "";
        this.data.phone= "";
        this.data.email= "";
        this.data.message="";
        }


    },

  },
};
</script>

<style></style>
