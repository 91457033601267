<template>
  <div class="works pad-60" id="works">
    <!--shape-->
    <div class="shape">
      <img src="@/assets/images/icons/image2.png" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <!--Main Title-->
          <div class="main_title center">
            <h2>أعمالنا</h2>
          </div>

          <!--Start Tab Content-->
          <div class="tab-content">
            <transition name="fadeIn">
              <div v-if="tabText === 'all'" class="fadeIn">
                <div class="row">
                  <div class="col-md-4" v-for="item in works" :key="item.id">
                    <WorkCard :item="item"></WorkCard>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkCard from "../UI/WorkCard.vue";
export default {
  components: {
    WorkCard,
  },
  data() {
    return {
      tabText: "all",
      works: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/1.jpeg"),
          title: "SSFCM",
          type: "Web",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/2.jpeg"),
          title: "DaleelFM",
          type: "Web",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/3.jpeg"),
          title: "URC 2024",
          type: "Web",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/4.jpeg"),
          title: "Tuwaiq Evenings",
          type: "Web",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/2.png"),
          title: "Mronah",
          type: "Web",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/9.png"),
          title: "Zal",
          type: "Mobile",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/1.png"),
          title: "Langlark",
          type: "Web",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/3.png"),
          title: "Luster",
          type: "Web",
        },
        
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          image: require("@/assets/images/works/7.png"),
          title: "Lime",
          type: "Web & Mobile",
        },
      ],
    };
  },
  methods: {
    //Tab Click
    tabClick(val) {
      this.tabText = val;
    },
    //Tab Click
  },
};
</script>

<style></style>
