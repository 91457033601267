<template>
  <div class="service_card">
    <!--Icon-->
    <div class="icon">
      <img :src="item.image" />
    </div>
    <!--Exp-->
    <div class="serv_exp">
      <h4>{{ item.title }}</h4>
      <p>{{ item.desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style></style>
