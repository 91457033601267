<template>
  <div class="about_us pad-60" id="about">
    <!--Shape-->
    <div class="shape">
      <img src="@/assets/images/icons/traingle1.png" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <!--Text-->
          <div class="about">
            <div class="main_title diff_style">
              <h3>من نحن</h3>
            </div>
            <p>
              ندعم الأفكار , نطورها وننفذها بأساليب احترافية، نجعلك تشاهد فكرتك منذ مولدها خطوة بخطوة وحتى انطلاقها بين كبار المشاريع المنافسة، نطمح لمساعدتك ودعمك
            </p>
            <!-- <div class="row">
              <div class="col-lg-3" v-for="item in items" :key="item.id">
                <Radial :series="item.series" :text="item.text"></Radial>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-md-6">
          <!--Image-->
          <div class="image">
            <img src="@/assets/images/main_section/about.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Radial from "../Global/RaduialProgress.vue";
export default {
  components: {
    // Radial,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          series: [30],
          text: "موقع الكتروني",
        },
        {
          id: 2,
          series: [50],
          text: "تطبيق الكتروني",
        },
        {
          id: 3,
          series: [70],
          text: "عملاء تم التعاقد معهم",
        },
        {
          id: 4,
          series: [90],
          text: "مصمم ومطور",
        },
      ],
    };
  },
};
</script>

<style></style>
