<template>
  <div class="homePage">
    <MainSection></MainSection>
    <Features></Features>
    <AboutUs></AboutUs>
    <Services></Services>
    <Works></Works>
    <Technology></Technology>
    <!-- <ClientOpinion></ClientOpinion> -->
    <ContactUs></ContactUs>
    <Partners></Partners>
  </div>
</template>

<script>
import MainSection from "@/components/Homepage/MainSection.vue";
import Features from "@/components/Homepage/Features.vue";
import AboutUs from "@/components/Homepage/AboutUs.vue";
import Services from "@/components/Homepage/Services.vue";
import Works from "@/components/Homepage/Works.vue";
import Technology from "@/components/Homepage/Technology.vue";
// import ClientOpinion from "@/components/Homepage/ClientOpinion.vue";
import ContactUs from "@/components/Homepage/ContactUs.vue";
import Partners from "@/components/Homepage/Partners.vue";
export default {
  components: {
    MainSection,
    Features,
    AboutUs,
    Services,
    Works,
    Technology,
    // ClientOpinion,
    ContactUs,
    Partners,
  },
  methods: {
    changeTheme() {
      this.$store.dispatch("theme_module/changeTheme");
      if (this.$store.getters["theme_module/current_theme"] == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
  },
};
</script>
