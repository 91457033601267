<template>
  <div class="main_section">
    <!--Left Image-->
    <div class="left_image">
      <img src="@/assets/images/icons/image2.png" />
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
          <!--Details-->
          <div class="details">
            <!--Title-->
            <h4>كل ما تحتاجه في مكان واحد</h4>
            <!--Sub Title-->
            <span></span>
            <!--Desc-->
            <p>نحرص  على تقديم التجربة الافضل لعملائنا من خلال خدماتنا المتنوعة للحصول على النتائج الافضل. منذ عام ٢٠١٧ عملنا   على تقديم الخدمات المختلفة التي تلاقي تطلعات العملاء من شتى المجالات و تمكينهم تقنيا من تحقيق اهدافهم برؤية عالمية و خدمات متميزة</p>
            <!--Contact-->
            <a href="#contact" class="main_button contact"><span> تواصل معنا  </span></a>
          </div>
        </div>
        <div class="col-md-7">
          <!--Image-->
          <div class="image">
            <img src="@/assets/images/main_section/image.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
